<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li>
          <p>操作类型：</p>
          <select @change="getData(1)" v-model="form.caozuoleibie">
            <option value="">全部</option>
            <option value="1">新增</option>
            <option value="2">修改</option>
            <option value="3">删除</option>
          </select>
        </li>
        <li>
          <p>操作时间：</p>
          <input type="date" @change="getData(1)" v-model="form.startcaozuoshijian"><span> &nbsp;-&nbsp; </span>
          <input type="date" @change="getData(1)" v-model="form.endcaozuoshijian">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <colgroup>
          <col style="width:200px">
          <col style="width:100px">
          <col style="width:180px">
          <col style="width:100px">
        </colgroup>
        <thead>
          <tr>
            <td style="text-align:center">操作时间</td>
            <td style="text-align:center">操作人</td>
            <td>操作对象</td>
            <td style="text-align:center">操作类型</td>
            <td>操作内容</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td style="text-align:center">{{dataFormat(item.caozuoshijian)}}</td>
            <td style="text-align:center">{{item.caozuorenName }}</td>
            <td>{{item.caozuobiao}}-{{item.caozuoduixiangName}}</td>
            <td style="text-align:center"><span :class="returnLBClass(item.caozuoleibie)">{{returnLB(item.caozuoleibie)}}</span></td>
            <td>{{item.caozuoneirong}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <span style="color:#F1C40F"><i class="icon-alert-circle"></i> 操作记录以月为单位保存一年，例：2023-04-29号可以查到2023-04-01号的操作记录。</span>
      </div>
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
export default {
  name: 'OperationRecord',
  data() {
    return {
      dataList:[],
      form:{
        caozuoleibie:'',
        startcaozuoshijian:'',
        endcaozuoshijian:'',
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
    }
  },
  created() {
     this.getData(1)
  },
  components: {},
  mounted() {},
  methods: {
    dataFormat(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    returnLBClass(lb){
      if(lb==1){
        return 'suc'
      }
      else if(lb==2){
        return 'cal'
      }
      else if(lb==3){
        return 'err'
      }
    },
    returnLB(lb){
      if(lb==1){
        return '新增'
      }
      else if(lb==2){
        return '修改'
      }
      else if(lb==3){
        return '删除'
      }
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/record/operationList',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }
      }).catch(() => {
        this.$Mesg({
          content: '获取数据失败！',
          errOrSuc: 'err',
        })
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    
  },
}
</script>
